@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.button {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  font: inherit;
  border: none;
  background-color: #ca000a;
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
  font-weight: bold;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 100%;
  background-color: #ffffff31;
  transform: translateX(-4em) skewX(-45deg);
  transition: 0.5s;
}

.button:hover::before {
  animation: move-light 0.7s;
}

@keyframes move-light {
  from {
    transform: translateX(-4em) skewX(-45deg);
  }
  to {
    transform: translateX(15em) skewX(-45deg);
  }
}

.adaptiv {
  display: none;
}

span {
  font-family: "Montserrat", sans-serif;

  font-size: 14px;
}

.button:hover,
.button:active {
  background-color: #ca000a77;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.badge {
  background-color: #c71212;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  font-weight: bold;
}

.button:hover .badge,
.button:active .badge {
  background-color: #f80324;
}

.bump {
  animation: bump 300ms ease-out;
}

@media (max-width: 1200px) {
  .button {
    border-radius: 25px;
  }
  .button::before {
    display: none;
  }
  .adaptiv {
    display: none;
  }
}
@media (max-width: 1000px) {
  .button {
    position: relative;
    padding: 5px;
    margin-top: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    overflow: visible;
    background-color: white;
  }
  .button:hover,
  .button:active {
    background-color: #ca000a17;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    color: black;
  }
  .badge {
    position: absolute;
    z-index: 0;
    left: 25px;
    bottom: 20px;
    background-color: #c71212ee;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50px;
    margin: 0;
    color: white;
  }
  .spanAdaptiv {
    display: none;
  }
}
@media (max-width: 768px) {
  .button {
    position: relative;
    padding: 5px;

    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
  .spanAdaptiv {
    display: none;
  }
}
@media (max-width: 480px) {
  .button {
    position: relative;
    padding: 5px;

    height: 45px;
    width: 45px;
    border-radius: 50px;
  }

  .spanAdaptiv {
    display: none;
  }
}
