@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans&display=swap");

a {
  text-decoration: none;
  color: #fff;
}

.footer {
  background-color: black;
  margin-top: 15px;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 10px;
}

.blockInfo {
  width: 350px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.blockInfo img {
  width: 30px;
  height: 30px;
}

.blockInfo p {
  margin-top: 20px;
  color: #ca000a;
}

.addressNet {
  display: flex;
  justify-content: space-between;
  padding-left: 25%;
  padding-right: 25%;
  width: 350px;
}
.nameNet {
  margin-top: 10px;
  text-transform: uppercase;
}

.nameNet p {
  text-align: center;
}

.blockImg {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 5px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockImg:hover {
  border-color: #ca000a;
  box-shadow: 0px 0px 25px #ca000a;
}

.address {
  width: 450px;
  height: 100%;
  text-align: center;
}

.address p {
  padding-top: 10px;
  text-transform: uppercase;
}
.blockInfoTwo {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 40px auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  border: 3px solid #ca000a;
  border-radius: 10px;
  background-color: #ca000a63;
}

.blockInfoTwo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 100%;
  background-color: #ffffff31;
  transform: translateX(-4em) skewX(-45deg);
  transition: 0.5s;
}

.blockInfoTwo:hover::before {
  animation: move-light 0.7s;
}

.addressINN {
  display: flex;
  font-size: 8px;
}
.PaddressINN {
  margin-right: 10px;
  color: rgba(148, 148, 148, 0.418);
}

@keyframes move-light {
  from {
    transform: translateX(-4em) skewX(-45deg);
  }
  to {
    transform: translateX(65em) skewX(-45deg);
  }
}

@media (max-width: 1200px) {
  .footer {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .footer {
    width: 100%;
  }
  .blockInfoTwo {
    width: 768px;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .footer {
    width: 100%;
  }
  .address {
    text-align: center;
    width: 50%;
  }
  .blockInfoTwo {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .blockInfoTwo {
    width: 100%;
  }
  .address {
    vertical-align: top;
  }
}
