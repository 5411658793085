.container {
  margin: 5px;
}

.pickupBlock {
  //width: 100%;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  font-size: 20px;
  margin-bottom: 15px;
  transition: background-color 0.3s;
  position: relative; /* Добавлено для абсолютного позиционирования */
}

#point1 {
  display: inline-block;
}

#point2 {
  display: inline-block;
}

.radioLabel {
  color: black;
  cursor: pointer;
  display: block; /* Сделать label блочным элементом */
  width: 100%;
  height: 100%;
  position: absolute; /* Абсолютное позиционирование */
  top: -20%;
  padding: 10px; /* Обеспечить padding */
  box-sizing: border-box; /* Включить padding и border в общую ширину и высоту */
  line-height: 40px;
  border-radius: 10px; /* Соответствует радиусу блока */
}

.svgIcon {
  display: inline-block;
  font-size: 25px;
  margin-right: 10px; /* Добавить отступ */
}

.pickupBlock:hover {
  border: 2px solid #fa2742; /* Цвет при наведении */
}

.active {
  border: 2px solid #fa2742;
}

@media (max-width: 480px) {
  .radioLabel {
    font-size: 18px;
  }
}
@media (max-width: 430px) {
  .radioLabel {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .radioLabel {
    font-size: 14px;
  }
}
@media (max-width: 360px) {
  .radioLabel {
    font-size: 12px;
  }
}
