@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Pacifico&family=Rubik+Mono+One&family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Pacifico&family=Roboto+Slab&family=Rubik+Mono+One&family=Russo+One&display=swap");

.header {
  margin: 0 auto;
  max-width: 1200px;
  height: 8rem;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.062);
  z-index: 10;
  border-radius: 20px 20px 0px 0px;
  color: #000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
}

.burger {
  text-align: center;
  display: none;
}

.nameCafe {
  display: block;
}

.img {
  display: flex;
  justify-content: center;
}

.nameCafe img {
  width: 90px;
  height: 90px;
}

.nameCafe h1 {
  font-size: 18px;
  font-family: "Lobster", cursive;
  font-family: "Pacifico", cursive;
  font-family: "Rubik Mono One", sans-serif;
  font-family: "Russo One", sans-serif;
  background: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nameCafe p {
  font-size: 16px;
  color: #000;
}

.title {
  max-width: 160px;
  text-align: center;
}
.title p {
  margin-top: 15px;
  font-family: "Pacifico", cursive;
  color: black;
  justify-content: flex-start;
  color: #000;
}

.textColor {
  color: #555;
}

.contacts {
  display: flex;
}

.contacts p,
.contacts a {
  color: #000;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 16px;
}

.contactsBlock {
  width: 220px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.telefon {
  color: #000;
  display: inline-block;
  margin: 5px 0;
}

.grafic {
  color: #000;
}

@media (max-width: 1000px) {
  .header {
    height: 6.5rem;
  }

  .nameCafe h1 {
    font-size: 14px;
  }

  .nameCafe img {
    width: 80px;
    height: 80px;
    margin: 0;
  }

  .title {
    margin: 0;
  }

  .contactsBlock {
    width: 200px;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
  }

  /* .title p {
    margin-top: 5px;
    height: 40px;
  } */
}

@media (max-width: 700px) {
  .burger {
    display: block;
  }
  .contactsBlock {
    display: none;
  }
  .lineZero {
    display: none;
  }
}

@media (max-width: 480px) {
  .title p {
    font-size: 16px;
    text-align: center;
    margin: 0;
    line-height: 1.2em;
  }
}
