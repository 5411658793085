.container {
  max-width: 125px;
  margin: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 5px 10px gray;
}

.price {
  font-size: 12px;
  color: black;
}

.h3 {
  color: black;
  font-size: 14px;
  text-align: center;
}

.p {
  color: black;
  font-size: 14px;
}

.button {
  display: flex;
  justify-content: center;
}

.basketButton {
  background-color: #ca000a;
  border-radius: 10px;
  width: 100px;
  padding: 5px;
  margin: 15px 0;
}

.inputContainer {
  background-color: white;
  border: 1px solid #ca000a;
  border-radius: 10px;
  width: 100px;
  padding: 5px;
  margin: 10px 0;
}

.input {
  display: flex;
  justify-content: space-between;
}

.quantityButton {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.219);
  cursor: pointer;
  background-color: #ca000a;
  border-radius: 100px;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.newInput {
  color: black;
  font-size: 20px;
}

.noActive {
  background-color: #5c5c5c;
  border-radius: 10px;
  width: 100px;
  padding: 5px;
  margin: 15px 0;
}
