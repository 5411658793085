@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans&display=swap");

.container {
  position: relative;
  margin: 20px;
  width: 250px;
  min-height: 390px;
  padding-bottom: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-size: 170% auto;
  background-repeat: no-repeat;
  z-index: 99;
}

.button {
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  bottom: 3%;
  width: 100%;
}

.imgPrice {
  position: relative;
}

.imgPrice img {
  display: block;
  width: 250px;
  height: 170px;
  border-radius: 20px 20px 0px 0px;
}

.price {
  position: absolute;
  z-index: 0;
  background-color: #ca000a;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  right: 5px;
  bottom: -30px;
  border: 5px solid #ca000a77;
  color: #fff;
}

.price h1 {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.title {
  color: #fff;
  margin-top: 25px;
}

.title h3,
p {
  margin: 0;
  height: 100%;
  color: #000;
}

.h3 {
  padding-left: 10px;
  font-size: 18px;
}

.description {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 10px;
  text-align: left;
}

.descriptionP {
  width: 80%;
  height: 100%;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
}

.p {
  white-space: pre-line;
  color: #000;
}

.descriptionSpan {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-family: "Roboto", sans-serif;
}

.descriptionSpan span {
  padding: 2px 6px 2px 2px;
  font-size: 12px;
  background-color: #000;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

@media (max-width: 1000px) {
  .container {
    width: 220px;
  }
  .price {
    width: 55px;
    height: 55px;
    bottom: -25px;
    font-size: 12px;
  }
  .imgPrice img {
    width: 220px;
    height: 150px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 180px;
    min-height: 300px;
    padding-bottom: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .imgPrice img {
    width: 180px;
    height: 120px;
  }
  .title h3 {
    font-size: 14px;
  }
  .title p {
    font-size: 10px;
  }
  .title span {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 180px;
    min-height: 300px;
    padding-bottom: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .imgPrice img {
    width: 180px;
    height: 120px;
  }
  .title h3 {
    font-size: 14px;
  }
  .title p {
    font-size: 10px;
  }
  .title span {
    font-size: 10px;
  }
}

@media (max-width: 390px) {
  .container {
    width: 170px;
    min-height: 360px;
    padding-bottom: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .imgPrice img {
    width: 100%;
  }
}
