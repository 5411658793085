@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans&display=swap");

.navigastion {
  padding-top: 25px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

li {
  position: relative;
  list-style-type: none;
  display: inline;
  margin-right: 10px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  z-index: 999;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
}

li::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ca000a;
  border-bottom: 1px solid #ca000a;
  border-radius: 10px;
  transition: 0.2s;
  transform: scaleX(0);
}

li::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-left: 1px solid #ca000a;
  border-right: 1px solid #ca000a;
  transition: 0.2s;
  border-radius: 10px;
  transform: scaleY(0);
}

li:hover::before,
li:hover::after {
  transform: scale(1);
}

.navigastion ul {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-left: 10px;
  padding-left: 20px;
  z-index: 10;
}

.fon {
  display: none;
}

.videoContainer {
  display: none;
}

.video {
  display: none;
}

.active {
  text-align: center;
  background-color: #ca000a;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 10px;
}

.bannerDiv {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.bannerBlock {
  padding: 10px;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.banner {
  color: black;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  height: 60%;
}

.deliveryBanner {
  color: black;
  font-size: 14px;
  text-align: center;
}
.deliveryBanner span {
  color: black;
  font-size: 12px;
}

@media (max-width: 1200px) {
  .navigastion ul {
    padding: 10px;
    align-items: center;
    font-size: 12px;
  }
  .navigastion li {
    font-size: 14px;
  }
}
@media (max-width: 1000px) {
  .navigastion {
    padding-top: 0;
  }

  .bannerDiv {
    margin-bottom: 10px;
  }

  .bannerBlock {
    border-bottom: 1px solid rgba(255, 255, 255, 0.815);
    margin: 10px 10px 0 10px;
    padding: 5px 5px 5px 5px;
  }

  .banner {
    color: white;
    padding: 0;
  }

  .deliveryBanner span {
    color: white;
  }

  .deliveryBanner {
    color: white;
  }

  .navigastion ul {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
  }

  li {
    color: white;
    margin-bottom: 5px;
  }

  .fon {
    display: block;
    position: absolute;
    background-color: #000000a9;
    width: 100%;
    height: 210px;
    z-index: 9;
  }

  .videoContainer {
    display: block;
    position: absolute;
    width: 100%; /* Ширина контейнера */
    height: 210px; /* Высота контейнера */
    overflow: hidden;
    z-index: 0;
  }

  .video {
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}
@media (max-width: 768px) {
  .navigastion ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .navigastion ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .navigastion {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
  }
  .navigastion ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
  .bannerBlock {
    font-size: 14px;
  }
  .videoContainer {
    height: 240px; /* Высота контейнера */
  }
  .fon {
    height: 240px;
  }
}

@media (max-width: 390px) {
  .navigastion li {
    font-size: 14px;
  }
  .bannerBlock {
    margin: 10px 5px 0 5px;
  }
  .banner {
    margin-bottom: 5px;
  }
}
