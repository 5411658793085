.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: block;
  z-index: 999;
}

.overlayNoActive {
  display: none;
}

.drawer {
  position: absolute;
  width: 450px;
  height: 100%;
  right: 0;
  background: #1d1d1d;
  box-shadow: -10px 4px 24px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.br {
  margin-top: 10px;
  border: 1px solid #891c21;
}

.title h2 {
  display: block;
  margin: 0;
  color: #fff;
}

.title img {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.CartTotalBlock {
  position: absolute;
  bottom: 10px;
  width: 90%;
}

.CartTotalBlock ul {
  margin-top: 20px;
  color: #fff;
}

.CartTotalBlock li {
  display: flex;
  align-items: flex-end;
  background-color: #1d1d1d;
  color: #fff;
}

.CartTotalBlock div {
  flex: 1;
  height: 1px;
  border-bottom: 1px dashed #dfdfdf;
  position: relative;
  top: -4px;
  margin: 0 7px;
}

.CartTotalBlock button {
  width: 100%;
  height: 44px;
  background-color: #891c21;
  border-radius: 10px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
}

.CartTotalBlock button:hover {
  background-color: #891c21c0;
}

.CartTotalBlock button:active {
  background-color: #891c21c0;
}

.item {
  height: 80%;
  overflow: auto;
}

.basketZero img {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  width: 100px;
  height: 100px;
}
.basketZero h3 {
  margin: 0;
  text-align: center;
  color: #fff;
}
button {
  color: #fff;
}

.blockItem {
  height: 90%;
  margin-bottom: 50px;
}

@media (max-width: 480px) {
  .drawer {
    width: 370px;
  }
}
