* {
  box-sizing: border-box;
}

/* При открытии модального окна */

html {
  font-family: sans-serif;
  background-color: #fff;
}

main {
  margin-top: 7rem;
}

body {
  overflow: hidden;
  background-color: #fff;
}
body::-webkit-scrollbar {
  width: 0; /* Убираем скроллбар в Webkit браузерах (Chrome, Safari) */
}

/* При закрытии модального окна */
body {
  overflow: auto; /* Восстанавливаем прокрутку */
}

body,
h1,
h2,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

main {
  margin-top: 2px;
}

button {
  border: none;
  outline: none;
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden; /* Скрыть горизонтальный скролл на маленьких экранах */
  }
}
