h3,
p,
label {
  color: #ffffff;
}
input {
  background-color: #ca000a8a;
}

.formDelivery {
  margin-bottom: 70px;
}

.checkboxAdress {
  border: 1px solid #ca000a;
  margin-top: 20px;
  padding: 10px;
}
.checkbox {
  margin: 20px 0px 20px 0px;
}

.choiceDelivery {
  display: flex;
  margin-bottom: 20px;
}
.deliv {
  margin-left: 50px;
}

.checkboxNotActive {
  display: none;
}

.checkboxActive {
  display: block;
  margin-bottom: 20px;
}

.back {
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
}

.back img {
  width: 30px;
  height: 30px;
}

.back p {
  justify-content: left;
  margin: 7px 0 0 5px;
}

.back p:hover {
  color: #ff07139f;
}

.inputAdress label {
  display: block;
}

.inputAdress input {
  margin: 10px 0;
  width: 100%;
  height: 30px;
  font-size: 15px;
  color: #fff;
}

input[type="radio"] {
  display: none;
  cursor: pointer;
}

input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 12px; /* Размер квадратной радиокнопки */
  height: 12px;
  border: 1px solid #ca000a; /* Цвет границы в выключенном состоянии */
  background-color: #fff; /* Цвет фона в выключенном состоянии */
  border-radius: 50%; /* Делаем квадрат круглым */
  margin-right: 5px; /* Расстояние между радиокнопкой и текстом */
  cursor: pointer;
}

/* Стили для включенной радиокнопки */
input[type="radio"]:checked + label::before {
  background-color: #ca000a; /* Цвет фона включенной радиокнопки */
  border-color: #ca000a; /* Цвет границы включенной радиокнопки */
  cursor: pointer;
}

.TitleDelivery {
  margin: 10px 0;
  font-size: 16px;
}

.times {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  grid-auto-rows: 30px;
  grid-gap: 0.5rem;
}

.buttonTimes {
  background-color: #ca000a;
  border: 1px dashed #555;
  height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.buttonTimes:hover {
  background-color: #000;
}

.active {
  background-color: #000;
  border: 1px dashed #555;
  height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.inputTime {
  display: block;
  width: 250px;
  height: 30px;
  color: #fff;
  background-color: #ca000a98;
}

.inputTimeActive {
  display: block;
  width: 250px;
  height: 30px;
  color: #fff;
  background-color: #ca000a;
}

.inputTimeAll {
  margin-top: 20px;
  display: block;
  width: 250px;
  height: 30px;
  color: #fff;
  background-color: #ca000a98;
}

.inputTimeSlice {
  margin-top: 20px;
  display: block;
  width: 250px;
  height: 30px;
  color: #fff;
  background-color: #ca000a;
  font-size: 18px;
}

input[type="time"] {
  text-align: center;
}

.infoDelivery {
  margin-top: 30px;
  text-align: start;
}

.deliveryBlock {
  display: flex;
  justify-content: space-between;
}

.labelBlock {
  margin-bottom: 15px;
}

.inputBlock {
  width: 120px;
}

@media (max-width: 480px) {
  .inputBlock {
    width: 100px;
  }
}
