h2,
p {
  color: #fff;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  background: linear-gradient(180deg, #000 29.73%, #555 100%);
  border-radius: 20px;
  border: 1px solid #ca000a;
}

.content {
  display: flex;
}

.itemClear {
  padding: 5px;
}

.itemClear img {
  height: 20px;
  width: 20px;
}

.itemImg {
  height: 90px;
}

.itemImg img {
  height: 90px;
  width: 135px;
  border-radius: 20px 0 0 20px;
}

.description {
  padding: 5px 0px;
  margin-left: 20px;
}

.title {
  width: 130px;
}
.title h2 {
  width: 210px;
  font-size: 14px;
}

.itemPrice {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.price {
  width: 60%;
  padding-top: 10%;
  margin-right: 30px;
  font-size: 14px;
}

/* Изменить шрифт .................................................................*/
.price p {
  font-size: 16px;
}

.input {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border: 1px solid #ca000a;
  width: 130px;
  padding: 4px;
  height: 40px;
  border-radius: 20px;
}

.newInput {
  width: 30px;
  height: 30px;
  font-size: 25px;
  margin: 0 10px;
  text-align: center;
}

.quantityButton {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.219);
  cursor: pointer;
  background-color: #ca000a;
  border-radius: 100px;
}

.quantityButton:hover {
  background-color: #ca000ac0;
}

.quantityButton p {
  text-align: center;
}

.clear {
  cursor: pointer;
}

.clear:hover {
  background-color: #fdf5e171;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .description {
    margin-left: 10px;
    width: 140px;
  }
  .title h2 {
    width: 150px;
    font-size: 14px;
  }
  .price p {
    font-size: 15px;
  }
  .input {
    margin-top: 20px;
    width: 120px;
    height: 40px;
  }
  .newInput {
    width: 15px;
  }
  .itemPrice {
    width: 150px;
  }
  .price {
    width: 15%;
  }
}
