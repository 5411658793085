.form {
  text-align: center;
}

.button {
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form .noActive {
  background-color: #555;
  width: 110%;
  border-color: white;
  cursor: pointer;
}
.form .noActive:hover {
  background-color: #555;
}

.form button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  font: inherit;
  cursor: pointer;
  background-color: #ca000a;
  border: 1px solid #ca000a;
  color: white;
  padding: 0.25rem 4rem;
  border-radius: 20px;
  font-weight: bold;
}

.form button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 100%;
  background-color: #ffffff31;
  transform: translateX(-4em) skewX(-45deg);
  transition: 0.5s;
}

.form button:hover::before {
  animation: move-light 0.7s;
}

.basketButton {
  background-color: #ca000a;
  border-radius: 10px;
  padding: 7px;
  text-align: center;
}

.addButton {
  display: flex;
  justify-content: center;
}

.p {
  color: black;
  margin: 20px 0px;
  text-align: center;
}

@keyframes move-light {
  from {
    transform: translateX(-4em) skewX(-45deg);
  }
  to {
    transform: translateX(15em) skewX(-45deg);
  }
}

.form button:hover,
.form button:active {
  background-color: #ca000ac0;
  border-color: white;
  color: white;
}

.sousItem {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  gap: 4;
}

.h2 {
  color: black;
  font-size: 16px;
  margin: 15px 0px;
}

.input {
  display: flex;
  margin: 0 auto;
  border: 1px solid #ca000a;

  padding: 4px;
  height: 40px;
  border-radius: 20px;
}

.quantityButton {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.219);
  cursor: pointer;
  background-color: #ca000a;
  border-radius: 100px;
  //padding-top: 3px;
  font-size: 16px;
}

.quantityButton:hover {
  background-color: #ca000ac0;
}

.newInput {
  width: 30px;
  height: 30px;
  font-size: 20px;
  margin: 0 10px;
  text-align: center;
  color: black;
}

@media (max-width: 1000px) {
  .form button {
    margin-top: 20px;
    padding: 0.25rem 2rem;
    border-radius: 20px;
    font-weight: bold;
    left: 12%;
    color: #fff;
  }
  .input {
    margin-left: 20%;
  }
}

@media (max-width: 768px) {
  .form button {
    left: 0;
  }
  .input {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .form button {
    left: 2%;
  }
  .input {
    margin-left: 5%;
  }
}
@media (max-width: 390px) {
  .form button {
    left: 0;
  }
  .input {
    margin-left: 5%;
  }
}
