@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Pacifico&family=Roboto+Slab&family=Rubik+Mono+One&family=Russo+One&display=swap");
.modalFinish {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modalFinishNoActive {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  display: none;
}

.drawerFinish {
  position: relative;
  border-radius: 12px;
  background-color: black;
  border: 1px solid black;
  box-shadow: -10px 4px 24px rgba(0, 0, 0, 0.1);
  height: 300px;
  width: 350px;
}

.blockFinish {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.infoTitle {
  margin: 0 auto;
  margin-top: 40%;
  text-align: center;
}
.infoTitle h2 {
  color: #ffffff;
}

.infoTitle p {
  margin-top: 10px;
}

.infoClose {
  position: absolute;
  right: 0;
  bottom: 80%;
  padding: 20px;
}

.infoClose img {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.blockImg {
  z-index: 2;
  width: 100%;
  height: 100%;
}

.span {
  color: green;
  font-size: 20px;
  font-family: "Lobster", cursive;
  font-family: "Pacifico", cursive;
  font-family: "Rubik Mono One", sans-serif;
  font-family: "Russo One", sans-serif;
}

.drawerImg {
  display: block;
  width: 250px;
  height: 250px;
  margin: 20px auto;
  opacity: 0;
  animation: mask 2s forwards;
}

@keyframes mask {
  100% {
    opacity: 0.5;
  }
}
