.content {
  margin: 0 auto;
  margin-top: 10px;
  top: 0;
  left: 0;
  width: 1200px;
  background-color: #fff;
  align-items: center;
  min-height: 80vh;
  z-index: 10;
  padding-bottom: 20px;
}

.container {
  width: 1200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.titleDelivery {
  border: 1px solid white;
  border-radius: 10px;
  display: flex;

  padding: 5px 20px;
  justify-content: space-between;
  margin: 0 20px;
}

@media (max-width: 1200px) {
  .content {
    width: 1000px;
  }
  .container {
    width: 1000px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1000px) {
  .content {
    width: 768px;
  }
  .container {
    width: 768px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .titleDelivery {
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .content {
    width: 100%;
    padding: 0;
  }
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .titleDelivery {
    font-size: 14px;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .content {
    width: 100%;
    padding-left: 0;
  }
  .container {
    width: 100%;
  }
  .titleDelivery {
  }
}
