.mainAddress {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  align-items: center;
  display: none;

  margin-bottom: 5px;
}

.container img {
  width: 40px;
  height: 50px;
}

.addressBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.address {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .mainAddress {
    display: block;
    width: 100%;
  }

  .container {
    display: flex;
    justify-content: center;
    background-color: #fff;
  }

  .container img {
    margin: 10px 10px 10px;
  }

  .address {
    padding: 10px 0px 5px 0px;
  }
  .address p {
    font-size: 14px;
    color: #000;
  }
  .address h2 {
    padding-right: 20px;
    font-size: 14px;
    color: #000;
  }
}

@media (max-width: 480px) {
  .mainAddress {
    width: 100%;
  }

  .addressBlock {
    margin-top: 5px;
  }
  .address {
    padding: 10px 0px 0px 0px;
  }
  .address p {
    font-size: 13px;
  }
  .address h2 {
    padding-right: 10px;
    font-size: 12px;
  }
  .container img {
    width: 40px;
    height: 50px;
    margin: 12px 0px 0px 4px;
  }
}
@media (max-width: 380px) {
  .address p {
    font-size: 11px;
  }
}
